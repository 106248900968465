import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseUrl } from 'src/app/framework/constants/url-constants';


@Injectable({
  providedIn: 'root'
})
export class ConfigureService {

  constructor(private readonly http: HttpClient) { }

  saveOrUpdateAppConfig(payload) {
    const href = `${BaseUrl.USER}/client/config`;
    return this.http.post<any>(href, payload);
  }

  getAppConfig() {
    const href = `${BaseUrl.USER}/client/config`;
    return this.http.get<any>(href);
  }
  

}
