import { environment } from '../../../environments/environment';

export const BaseUrl = {
    AUTHENTICATE: environment.apiURL +'/sequation-user-api',
    CONTENT_MANAGEMENT: environment.apiURL +'/sequation-content-management-v2-api',
    USER: environment.apiURL + '/sequation-user-api',
    ORGANIZATION: environment.apiURL + '/sequation-organization-api',
    AUTHORIZATION: environment.apiURL + '/sequation-authorization-api',
    ASSESSMENT: environment.apiURL + '/sequation-community-assessment-api',
    USER_MANAGEMENT: environment.apiURL + '/sequation-user-management-api',
    SRM: environment.apiURL + '/sequation-srm-v2-api',
    CVB: environment.apiURL + '/sequation-cv-builder-v2-api',
    Document: environment.apiURL + '/sequation-document-v2-api',
    LOGO: environment.logo,
    APPTITLE: environment.appTitle,
    JOBS : environment.apiURL + '/sequation-job-v2-api',
    PLAN :  environment.apiURL +'/sequation-plan-v2-api',
    OPENVIDU :  environment.apiURL +'/sequation-openvidu-v2-api',
    CLIENT_URL: window.location.host,
    MOODLE_API: environment.apiURL +'/sequation-moodle-v2-api',
    POWERBI: environment.apiURL +'/sequation-report-api'


};
